import { ButtonRow, CommonProps } from './common';
import { Button, Modal, Spin } from 'antd';
import { AleoTypography } from '@aleohq/components';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store';
import { setSignIn, setSignUp } from '../../store/modal.slice';
import { SignInModal, SignUpModal, UnsupportedBrowserModal } from '../index';
import React, { useEffect, useState } from 'react';
import { palette } from '@aleohq/ui/dist/palette';
import useAuth from '../../hooks/use-auth';
import { detect } from 'detect-browser';
import { useHistory } from 'react-router-dom';

const browser = detect();

const List = styled.ul`
    padding-inline-start: 15px;
    margin-top: 25px;

    li {
        margin-bottom: 20px;
    }

    li::marker {
        color: ${palette.green6};
    }
`;

const Step1Auth = (props: CommonProps) => {
    const history = useHistory();
    const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);
    const { signInVisible, signUpVisible } = useAppSelector((state) => state.modal);
    const dispatch = useAppDispatch();
    const { user, userLoading, signOut } = useAuth();

    const confirm = () => {
        Modal.info({
            title: (
                <AleoTypography textStyle="subtitle">
                    Your contribution to the Aleo Setup Ceremony is about to start.
                </AleoTypography>
            ),
            content: (
                <List>
                    <li>
                        <AleoTypography textStyle="body">Do not close the browser window or tab.</AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">
                            Be sure that you have a stable internet connection for the duration of your contribution.
                        </AleoTypography>
                    </li>
                </List>
            ),
            centered: true,
            icon: null,
            closable: true,
            maskClosable: true,
            width: '415px',
            okButtonProps: {
                size: 'large'
            },
            okText: 'I Understand',
            className: 'setup-modal',
            onOk: props.onNextStep
        });
    };

    useEffect(() => {
        props.onNextStep();
    }, [props]);

    useEffect(() => {
        const version = Math.floor(Number(browser?.version));

        switch (browser?.name) {
            case 'chrome':
            case 'edge':
                if (version < 83) {
                    setUnsupportedBrowser(true);
                    return;
                }

                break;
            case 'firefox':
                if (version < 79) {
                    setUnsupportedBrowser(true);
                    return;
                }

                break;
            default:
                setUnsupportedBrowser(true);
                return;
        }
    }, []);

    return (
        <Spin spinning={userLoading} size="large">
            {user && (
                <>
                    <AleoTypography textStyle="heading-3" style={{ maxWidth: 455 }}>
                        You are contributing with your Aleo account.
                    </AleoTypography>
                    <ButtonRow>
                        <Button size="large" type="primary" onClick={confirm}>
                            Start
                        </Button>
                        <AleoTypography textStyle="body" noMargin>
                            or
                        </AleoTypography>
                        <Button size="large" onClick={signOut}>
                            Sign Out
                        </Button>
                    </ButtonRow>
                </>
            )}

            {!user && (
                <>
                    <AleoTypography textStyle="heading-3" style={{ maxWidth: 455 }}>
                        How would you like to contribute to the Aleo Setup?
                    </AleoTypography>

                    <ButtonRow>
                        <Button size="large" type="primary" onClick={() => dispatch(setSignIn(true))}>
                            Aleo Account
                        </Button>
                        <AleoTypography textStyle="body" noMargin>
                            or
                        </AleoTypography>
                        <Button size="large" onClick={confirm}>
                            Anonymous
                        </Button>
                    </ButtonRow>
                </>
            )}

            <SignInModal onAuth={confirm} visible={signInVisible} onCancel={() => dispatch(setSignIn(false))} />
            <SignUpModal onAuth={confirm} visible={signUpVisible} onCancel={() => dispatch(setSignUp(false))} />
            <UnsupportedBrowserModal visible={unsupportedBrowser} onCancel={() => history.push('/inner-setup-cli')} />
        </Spin>
    );
};

export default Step1Auth;
