import useSWR from 'swr';

interface Contributor {
    id: string;
    round_height: number;
    reliability: number;
}

export interface CoordinatorResponse {
    banned: string[];
    current_contributors: {
        [key: string]: Contributor;
    };
    current_verifiers: {
        [key: string]: Contributor;
    };
    current_metrics: {
        number_of_contributors: number;
        estimated_finish_time: number;
        estimated_wait_time: number;
    };
    current_round_height: number;
    dropped: { id: string }[];
    finished_contributors: {
        [key: string]: {
            [key: string]: Contributor;
        };
    };
    status: string;
    queue: {
        [key: string]: {
            0: number;
            1: number;
            2: string;
        };
    };
}

export interface StateResponse {
    chunks: {
        chunkId: number;
        contributions: {
            [key: number]: {
                contributedLocation: string | null;
                contributedSignatureLocation: string | null;
                contributorId: string | null;
                verified: boolean;
                verifiedLocation: string;
                verifiedSignatureLocation: string;
                verifierId: string;
            };
        };
    }[];
    contributorIds: string[];
    finishedAt?: string;
    height: number;
    startedAt: string;
    verifierIds: string[];
    version: number;
}

export const useStatus = (ceremony: 'universal' | 'outer' | 'inner', roundHeight?: number) => {
    const urlMap = {
        universal: process.env.NODE_ENV === 'development' ? '/universal-status' : 'https://ceremony.aleo.org',
        outer: process.env.NODE_ENV === 'development' ? '/outer-status' : 'https://outer-ceremony.aleo.org',
        inner: process.env.NODE_ENV === 'development' ? '/inner-status' : process.env.REACT_APP_CEREMONY_URL
    };

    const { data: coordinatorData, error: coordinatorError } = useSWR<CoordinatorResponse>(
        `${urlMap[ceremony]}/transcript/coordinator.json`,
        { refreshInterval: 5000 }
    );

    const { data: stateData, error: stateError } = useSWR<StateResponse>(
        () =>
            coordinatorData
                ? `${urlMap[ceremony]}/transcript/round_${
                      roundHeight ? roundHeight : coordinatorData.current_round_height
                  }/state.json`
                : null,
        { refreshInterval: 5000 }
    );

    return {
        coordinatorData,
        coordinatorError,
        stateData,
        stateError,
        loading: !coordinatorData && !coordinatorError && !stateData && !stateError
    };
};
