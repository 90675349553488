import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modal.slice';
import walletReducer from './wallet.slice';
import authReducer from './user.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        modal: modalReducer,
        wallet: walletReducer,
        auth: authReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
