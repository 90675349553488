import styled from 'styled-components';
import { Space } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';

export type BackUpPage = 'PRIVATE_KEY_ONLY' | 'MENU' | 'MNEMONIC' | 'PRIVATE_KEY' | 'JSON_FILE' | 'SUCCESS';

export interface CommonProps {
    onNextStep: () => void;
    onPrevStep?: () => void;
}

export interface BackUpProps {
    onBack: () => void;
    onNext: (page?: BackUpPage) => void;
}

export const ButtonRow = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 40px;
`;

export const BackUpWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
`;

export const BackUpContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
`;

export const BackUpButtons = styled(Space)`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
    padding-top: 40px;
    border-top: 1px solid ${palette.gray9};
`;
