import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WalletData {
    address?: string;
    privateKey?: string;
    confKeyHash?: string;
    privateConfKey?: string;
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState: {} as WalletData,
    reducers: {
        setWalletData(state, action: PayloadAction<WalletData>) {
            state.address = action.payload.address;
            state.privateKey = action.payload.privateKey;
            state.confKeyHash = action.payload.confKeyHash;
            state.privateConfKey = action.payload.privateConfKey;
        }
    }
});

export const { setWalletData } = walletSlice.actions;
export default walletSlice.reducer;
