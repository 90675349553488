import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProps } from './types';

const DeleteCircleSvg = () => (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M58.203 58.2019C71.0171 45.3879 71.0171 24.6121 58.203 11.7981C45.3889 -1.01602 24.6132 -1.01602 11.7991 11.7981C-1.01497 24.6121 -1.01497 45.3879 11.7991 58.2019C24.6132 71.016 45.3889 71.016 58.203 58.2019Z"
                stroke="#FF5555"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M47.03 47.0323L22.9686 22.9688M47.03 22.9698L22.9675 47.0323L47.03 22.9698Z"
                stroke="#FF5555"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="70" height="70" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const DeleteCircleIcon = (props: CustomIconProps) => <Icon component={DeleteCircleSvg} {...props} />;
