import { createAsyncThunk, createSlice, Draft, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import {
    currentUserRequest,
    loginRequest,
    LoginRequest,
    registerRequest,
    RegisterRequest,
    UserProfile
} from '../api/user';

interface AuthState {
    user: UserProfile | null;
    error: SerializedError | null;
    loading: boolean;
}

export const fetchCurrentUser = createAsyncThunk<UserProfile>('users/fetchCurrent', async () => {
    const userResponse = await currentUserRequest();
    return userResponse.data;
});

export const authenticateUser = createAsyncThunk<UserProfile, LoginRequest, {}>(
    'users/authenticate',
    async (data, thunkApi) => {
        const response = await loginRequest(data);
        localStorage.setItem('apm-token', response.data.token);

        const userResponse = await currentUserRequest();

        return userResponse.data;
    }
);

export const registerUser = createAsyncThunk<UserProfile, RegisterRequest, {}>(
    'users/register',
    async (data, thunkApi) => {
        await registerRequest(data);

        const response = await loginRequest({
            email_username: data.username,
            password: data.password
        });

        localStorage.setItem('apm-token', response.data.token);

        const userResponse = await currentUserRequest();

        return userResponse.data;
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null as UserProfile | null,
        error: null as SerializedError | null,
        loading: false
    } as AuthState,
    reducers: {
        logoutUser(state, _action: PayloadAction) {
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        const authFulfilled = (state: Draft<AuthState>, action: PayloadAction<UserProfile>) => {
            state.user = action.payload;
            state.loading = false;
        };

        const authRejected = (state: Draft<AuthState>, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        };

        const authPending = (state: Draft<AuthState>, action: PayloadAction) => {
            state.loading = true;
            state.error = null;
            state.user = null;
        };

        builder.addCase(authenticateUser.fulfilled, authFulfilled);
        builder.addCase(authenticateUser.rejected, authRejected);
        builder.addCase(authenticateUser.pending, authPending);

        builder.addCase(registerUser.fulfilled, authFulfilled);
        builder.addCase(registerUser.rejected, authRejected);
        builder.addCase(registerUser.pending, authPending);

        builder.addCase(fetchCurrentUser.fulfilled, authFulfilled);
        builder.addCase(fetchCurrentUser.rejected, authRejected);
        builder.addCase(fetchCurrentUser.pending, authPending);
    }
});

export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;
