import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
    signUpVisible: boolean;
    signInVisible: boolean;
}

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        signInVisible: false,
        signUpVisible: false
    } as ModalState,
    reducers: {
        setSignIn(state, action: PayloadAction<boolean>) {
            state.signInVisible = action.payload;
        },
        setSignUp(state, action: PayloadAction<boolean>) {
            state.signUpVisible = action.payload;
        }
    }
});

export const { setSignIn, setSignUp } = modalSlice.actions;
export default modalSlice.reducer;
