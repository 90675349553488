import styled from 'styled-components';
import { Radio } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';

export const FilterButtonWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
`;

export const FilterButtons = styled(Radio.Group)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 500px) {
        gap: 0 10px;
        justify-content: start;
    }

    .ant-radio-button-wrapper {
        border-radius: 25px;
        border: 1px solid ${palette.gray5};
        padding: 0 24px;

        @media (max-width: 800px) {
            margin-bottom: 10px;
            padding: 0 20px;
            font-size: 14px;
            line-height: 32px;
            height: 32px;
        }

        &:first-child {
            margin-left: 20px;

            @media (max-width: 800px) {
                margin-left: 0;
            }
        }

        &:before {
            display: none;
        }

        &:focus-within {
            box-shadow: none;
        }

        &.ant-radio-button-wrapper-checked {
            border-color: transparent !important;
            background: rgba(24, 228, 143, 0.1);

            &:hover {
                color: ${palette.green6};
            }
        }
    }
`;
