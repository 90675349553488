import React from 'react';
import { AleoTypography } from '@aleohq/components';
import styled from 'styled-components';
import { AleoCollapse, ExternalLink, MinusIcon, PlusIcon } from '../components';

const FaqWrapper = styled.div`
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

// const FAQTable = styled.table`
//     font-family: Inter;
//     font-size: 14px;
//     line-height: 22px;
//     width: 100%;
//     min-width: 600px;
//     margin-top: 40px;
//     table-layout: fixed;
//     border-radius: 10px;
//     border-spacing: 0;
//     border-collapse: separate;
//
//     thead {
//         background: ${palette.gray4};
//     }
//
//     tr:first-child th:first-child {
//         border-top-left-radius: 10px;
//     }
//
//     tr:first-child th:last-child {
//         border-top-right-radius: 10px;
//     }
//
//     tr:last-child td:first-child {
//         border-bottom-left-radius: 10px;
//     }
//
//     tr:last-child td:last-child {
//         border-bottom-right-radius: 10px;
//     }
//
//     th {
//         color: ${palette.gray9};
//     }
//
//     th,
//     td {
//         padding: 8px;
//         border: 1px solid ${palette.gray4};
//     }
//
//     td {
//         box-sizing: border-box;
//         background-color: ${palette.gray1};
//     }
// `;

const FAQ = () => {
    return (
        <div>
            <AleoTypography textStyle="title" weight="semibold">
                FAQ
            </AleoTypography>
            <AleoTypography textStyle="body" style={{ maxWidth: 550 }}>
                The zero-knowledge proofs that power Aleo rely on a set of parameters that are generated in a trustless
                manner. Please click on the headings below to learn more about the Aleo Setup Ceremony and why it
                matters.
            </AleoTypography>

            <FaqWrapper>
                <AleoCollapse
                    defaultActiveKey={['1']}
                    bordered={false}
                    ghost
                    expandIcon={(panelProps) => (panelProps.isActive ? <MinusIcon /> : <PlusIcon />)}
                >
                    <AleoCollapse.Panel header="What is Aleo?" key="1">
                        <a href="https://aleo.org" target="_blank" rel="noreferrer">
                            Aleo
                        </a>{' '}
                        is the first blockchain to offer fully private applications. Aleo puts emphasis on being
                        privacy-native and building privacy into its foundation. Aleo provides full (a.k.a. functional)
                        privacy, which not only offers privacy for aspects like token transfers or account management,
                        but also protects the privacy of any user-specified action.
                    </AleoCollapse.Panel>
                    <AleoCollapse.Panel header="What is Aleo Setup I?" key="2">
                        Aleo Setup I will secure the three zkSNARKs that form the foundations of Aleo. Similar to
                        Zcash’s Powers of Tau, Aleo Setup I is a multiparty computation (MPC) ceremony where
                        participants from around the world contribute their compute power to ensure the security of the
                        system. As with all SNARK setup MPCs, if just one person behaves honestly, the setup is
                        guaranteed to be secure.
                        <br /> <br />
                        Aleo implements <ExternalLink href="https://eprint.iacr.org/2018/962.pdf">ZEXE</ExternalLink> -
                        joint research between UC Berkeley, Cornell Tech, JHU, and Zcash - to enable a novel computing
                        paradigm known as decentralized private compute (DPC). This construction, along with the ledger
                        upon which it is built, makes up{' '}
                        <ExternalLink href="https://www.aleo.org/post/zkcloud">zkCloud</ExternalLink>, a new framework
                        on which to deploy scalable, private applications for the decentralized web.
                    </AleoCollapse.Panel>
                    <AleoCollapse.Panel header="How do I participate?" key="3">
                        Head over to <ExternalLink href="https://setup.aleo.org">setup.aleo.org</ExternalLink> and check
                        out the opportunities to contribute to the Inner, Outer, and Universal Setups. Each of these
                        corresponds to one of the three zkSNARKs that form the foundation of Aleo.
                        <br />
                        <br />
                        For the Inner Setup, contributing is as easy as leaving a tab open on a supported web browser
                        for about an hour while the process completes. For the Outer and Universal Setups, some
                        knowledge of the command line and a more powerful PC is required.
                    </AleoCollapse.Panel>
                    <AleoCollapse.Panel header="Where do I learn more?" key="4">
                        To learn more about setup ceremonies, and why they are important, check out this{' '}
                        <ExternalLink href="https://medium.com/zeroknowledge/the-power-of-tau-or-how-i-learned-to-stop-worrying-and-love-the-setup-535a05bec15d">
                            blog post
                        </ExternalLink>
                        .
                        <br />
                        <br />
                        To learn more about zero knowledge proofs, here are three great resources:
                        <ul>
                            <li>
                                Beginner:{' '}
                                <ExternalLink href="https://z.cash/technology/zksnarks/">
                                    https://z.cash/technology/zksnarks
                                </ExternalLink>
                            </li>
                            <li>
                                Intermediate:{' '}
                                <ExternalLink href="https://tlu.tarilabs.com/cryptography/zksnarks/mainreport.html">
                                    https://tlu.tarilabs.com/cryptography/zksnarks/mainreport.html
                                </ExternalLink>
                            </li>
                            <li>
                                Advanced:{' '}
                                <ExternalLink href="https://electriccoin.co/blog/snark-explain/">
                                    https://electriccoin.co/blog/snark-explain/
                                </ExternalLink>
                            </li>
                        </ul>
                        And to learn more about how to build on Aleo, check out our documentation at{' '}
                        <ExternalLink href="https://developer.aleo.org">developer.aleo.org</ExternalLink>.
                    </AleoCollapse.Panel>
                    <AleoCollapse.Panel header="How do I get in touch?" key="5">
                        Join the{' '}
                        <a href="https://discord.gg/SMXsDEQ" target="_blank" rel="noreferrer">
                            Discord channel
                        </a>{' '}
                        for any questions or to join the discussion.
                    </AleoCollapse.Panel>
                    {/*<AleoCollapse.Panel*/}
                    {/*    header="What’s the difference between the Browser Setup and the Desktop Setup?"*/}
                    {/*    key="6"*/}
                    {/*>*/}
                    {/*    The <b>Browser Setup</b> will take about 2.5 hours to complete. This secures the Inner SNARK,*/}
                    {/*    which is run on the transaction kernel to produce an inner proof. This is the easy option,*/}
                    {/*    requiring no prerequisite knowledge of SNARKs or setups to be part of history! Everyone is*/}
                    {/*    encouraged to participate in the Browser Setup.*/}
                    {/*    <br />*/}
                    {/*    <br />*/}
                    {/*    The <b>Desktop Setup</b> will take over 36 hours to complete. This secures the Outer SNARK (the*/}
                    {/*    recursive part of the system) as well as the Universal Program SNARKs. This does require more*/}
                    {/*    knowledge of running software from source and is only recommended for more technical*/}
                    {/*    participants.*/}
                    {/*    <br />*/}
                    {/*    <FAQTable>*/}
                    {/*        <thead>*/}
                    {/*            <tr>*/}
                    {/*                <th />*/}
                    {/*                <th>Requirements</th>*/}
                    {/*                <th>Timing</th>*/}
                    {/*                <th>Technical Expertise Need</th>*/}
                    {/*            </tr>*/}
                    {/*        </thead>*/}

                    {/*        <tbody>*/}
                    {/*            <tr>*/}
                    {/*                <td>Universal Setup</td>*/}
                    {/*                <td>Lorem ipsum</td>*/}
                    {/*                <td>Lorem ipsum</td>*/}
                    {/*                <td>Lorem ipsum</td>*/}
                    {/*            </tr>*/}
                    {/*            <tr>*/}
                    {/*                <td>Outer Setup</td>*/}
                    {/*                <td>Lorem ipsum</td>*/}
                    {/*                <td>Lorem ipsum</td>*/}
                    {/*                <td>Lorem ipsum</td>*/}
                    {/*            </tr>*/}
                    {/*            <tr>*/}
                    {/*                <td>Inner Setup</td>*/}
                    {/*                <td>Updated Browser</td>*/}
                    {/*                <td>2.5 Hours</td>*/}
                    {/*                <td>None</td>*/}
                    {/*            </tr>*/}
                    {/*        </tbody>*/}
                    {/*    </FAQTable>*/}
                    {/*</AleoCollapse.Panel>*/}

                    <AleoCollapse.Panel header="What do I get for participating?" key="7">
                        Participating in the Aleo setup ceremony is being a part of the history of what we hope becomes
                        an important open-source ecosystem supporting the development of zero-knowledge proofs! Your
                        contribution is critical to ensuring that the paramaters for our network are generated in a
                        secure, trustless manner.
                        <br />
                        <br />
                        As of now, there is no monetary reward for completing the setup ceremony. However, we want to
                        share a memento with everyone who contributes, in the form of a limited edition Aleo NFT. So
                        please make sure you don't forget to provide your Ethereum address at the end of the process!
                    </AleoCollapse.Panel>

                    <AleoCollapse.Panel header="How many times can I participate?" key="8">
                        Although you can contribute more than once, doing so does not add to the security of the
                        parameters. Furthermore, it is time and resource-consuming. So we ask that you refrain from
                        contributing more than once, to give others the opportunity to participate.
                    </AleoCollapse.Panel>
                </AleoCollapse>
            </FaqWrapper>
        </div>
    );
};

export default FAQ;
