import styled from 'styled-components';
import { Collapse } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';

export const AleoCollapse = styled(Collapse)`
    .ant-collapse-item {
        border: 1px solid ${palette.gray4};
        border-radius: 20px !important;
        margin-bottom: 20px;
        padding: 40px;
        transition: background-color 0.3s;

        @media (max-width: 800px) {
            padding: 10px;
            overflow-x: scroll;
        }

        a {
            border-bottom: 1px solid ${palette.green6};
            transition: all 0.3s;

            &:hover {
                border-bottom-color: ${palette.green5};
            }
        }

        .ant-collapse-header {
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;
            transition: none;
            display: flex;
            align-items: center;
            padding: 12px 0;

            .anticon {
                color: ${palette.gray9} !important;
            }

            .ant-collapse-arrow {
                padding: 0;
                position: static;
                margin-right: 20px;
            }
        }

        .ant-collapse-content {
            color: #ffffff;
            font-size: 16px;
            line-height: 24px;
            margin-left: 28px;
        }

        &.ant-collapse-item-active {
            border: 1px solid transparent;
            background: ${palette.gray3};

            .ant-collapse-header {
                color: ${palette.green6};
                font-weight: 600;
            }
        }
    }
`;
