import styled, { css } from 'styled-components';
import { Button, Card, Col, Typography } from 'antd';
import logo from '../assets/logo.svg';
import errorGraphic from '../assets/error-graphic.png';
import { palette } from '@aleohq/ui/dist/palette';
import React from 'react';
import { Link } from 'react-router-dom';

export const RightAlignCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const linkStyle = css`
    border-bottom: 1px solid ${palette.green6};
    transition: all 0.3s;

    &:hover {
        border-bottom-color: #179b64;
    }
`;

const StyledLink = styled.a`
    ${linkStyle}
`;

export const InternalLink = styled(Link)`
    ${linkStyle};
`;

export const ExternalLink = (props: React.HTMLProps<HTMLAnchorElement> & { children: React.ReactNode }) => {
    const { children, href, style, className, id } = props;

    return (
        <StyledLink target="_blank" rel="noreferrer" href={href} style={style} className={className} id={id}>
            {children}
        </StyledLink>
    );
};

export const Logo = styled.div`
    width: 105px;
    height: 30px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
`;

export const SubTitle = styled(Typography.Title)`
    &.ant-typography {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 10px;
    }
`;

export const PageTitle = styled(Typography.Title)`
    &.ant-typography {
        font-size: 30px;
        line-height: 38px;
        font-weight: normal;
        color: #ffffff;
        margin-top: 0 !important;
    }
`;

export const SetupCard = styled(Card)`
    border: none;
    height: 480px;
    width: 415px;

    .ant-card-body {
        padding: 45px;
        height: 100%;
    }
`;

interface OrionIconProps {
    icon: string;
    fontSize?: number;
    color?: string;
}

export const OrionIcon = (props: OrionIconProps) => {
    return <i className={`o-${props.icon}-1`} style={{ fontSize: props.fontSize, color: props.color }} />;
};

export const TextButton = styled.span`
    color: #ffffff;
    cursor: pointer;
`;

export const HeaderButton = styled(Button)`
    &.ant-btn-link {
        color: ${palette.gray9};
        border-color: transparent;
        padding: 0;
        text-align: right;
        font-size: 14px;
        line-height: 22px;
        min-width: unset;

        &:hover,
        &:focus {
            border-color: transparent;
            color: #ffffff;
        }

        &:active {
            border-color: transparent;
            color: #ffffff;
        }

        &:disabled {
            border-color: transparent !important;
            background: transparent !important;
        }
    }
`;

export const TableHeader = styled(Card)`
    .ant-card-body {
        padding: 40px;
        display: flex;
        align-items: center;
        min-height: 120px;

        @media (max-width: 800px) {
            padding: 10px;
        }
    }

    border-radius: 20px 20px 0 0;
    border: none;
    background-color: ${palette.gray2};
`;

export const ErrorWrapper = styled.div`
    flex: 1;
    position: relative;
    top: -100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ErrorGraphic = styled.div`
    height: 430px;
    width: 430px;
    background-image: url(${errorGraphic});
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ErrorInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
