import React from 'react';
import { BackUpButtons, BackUpContent, BackUpProps, BackUpWrapper } from '../common';
import { AleoTypography } from '@aleohq/components';
import { Button } from 'antd';
import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';
import { useAppSelector } from '../../../store';

const PhraseWrapper = styled.div`
    margin-top: 65px;
    margin-bottom: 20px;
    border-top: 1px solid ${palette.gray4};
    border-bottom: 1px solid ${palette.gray4};
    padding: 30px 40px;
`;

const BackUpPrivateKey = (props: BackUpProps) => {
    const { privateKey } = useAppSelector((state) => state.wallet);

    const copy = () => {
        if (privateKey) {
            navigator.clipboard.writeText(privateKey);
        }
    };

    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3">Back Up using Private Key</AleoTypography>

                <AleoTypography textStyle="body" style={{ maxWidth: 500 }}>
                    Please copy your private keystring and store it in a safe place. It will allow you to recover your
                    account.
                </AleoTypography>

                <PhraseWrapper>
                    <AleoTypography textStyle="body" noMargin>
                        {privateKey}
                    </AleoTypography>
                </PhraseWrapper>

                <Button size="large" type="primary" onClick={copy}>
                    Copy
                </Button>
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={() => props.onBack()}>
                    Back
                </Button>

                <Button type="primary" size="large" onClick={() => props.onNext('SUCCESS')}>
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default BackUpPrivateKey;
