import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tabs } from 'antd';
import styled from 'styled-components';
import { AleoTypography } from '@aleohq/components';
import dayjs from 'dayjs';

const RightAlignCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const StatisticCard = styled(Card)`
    border: none;

    @media (max-width: 800px) {
        width: 100%;
    }

    .ant-card-body {
        min-width: 350px;
        padding: 40px;
        box-sizing: border-box;

        @media (max-width: 600px) {
            min-width: 100%;
            padding: 30px 25px;
        }

        .ant-tabs-tab-btn {
            font-size: 12px;
            line-height: 20px;
        }

        .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
            color: #ffffff;
        }
    }
`;

const CountdownWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CountdownItem = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const CountdownLabel = styled.p`
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: #ffffff;
`;

const Separator = styled.span`
    position: relative;
    bottom: 12px;

    &:before {
        content: ':';
        font-size: 38px;
        padding: 0 6px;
    }
`;

interface CountdownProps {
    to?: Date;
    from?: Date;
}

const Countdown = (props: CountdownProps) => {
    const [days, setDays] = useState<string>();
    const [hours, setHours] = useState<string>();
    const [minutes, setMinutes] = useState<string>();
    const [seconds, setSeconds] = useState<string>();

    useEffect(() => {
        const calculateTime = () => {
            const now = dayjs();
            const to = dayjs(props.to);
            const from = dayjs(props.from);

            let diff;

            if (props.to && !props.from) {
                diff = to.diff(now);
            } else if (!props.to && props.from) {
                diff = now.diff(from);
            } else {
                diff = from.diff(to);
            }

            const d = Math.max(Math.floor(diff / (1000 * 60 * 60 * 24)), 0)
                .toString()
                .padStart(2, '0');
            const h = Math.max(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 0)
                .toString()
                .padStart(2, '0');
            const m = Math.max(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)), 0)
                .toString()
                .padStart(2, '0');
            const s = Math.max(Math.floor((diff % (1000 * 60)) / 1000), 0)
                .toString()
                .padStart(2, '0');

            setDays(d);
            setHours(h);
            setMinutes(m);
            setSeconds(s);
        };

        calculateTime();

        const interval = setInterval(calculateTime, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [props]);

    return (
        <CountdownWrapper>
            <CountdownItem>
                <AleoTypography textStyle="heading-2" weight="semibold" noMargin>
                    {days}
                </AleoTypography>

                <CountdownLabel>Days</CountdownLabel>
            </CountdownItem>
            <Separator />
            <CountdownItem>
                <AleoTypography textStyle="heading-2" weight="semibold" noMargin>
                    {hours}
                </AleoTypography>

                <CountdownLabel>Hours</CountdownLabel>
            </CountdownItem>
            <Separator />
            <CountdownItem>
                <AleoTypography textStyle="heading-2" weight="semibold" noMargin>
                    {minutes}
                </AleoTypography>

                <CountdownLabel>Minutes</CountdownLabel>
            </CountdownItem>
            <Separator />
            <CountdownItem>
                <AleoTypography textStyle="heading-2" weight="semibold" noMargin>
                    {seconds}
                </AleoTypography>

                <CountdownLabel>Seconds</CountdownLabel>
            </CountdownItem>
        </CountdownWrapper>
    );
};

const Heading = () => {
    return (
        <Row gutter={[8, 40]}>
            <Col lg={14} md={12} xs={24}>
                <AleoTypography textStyle="title" mobileTextStyle="heading-2" weight="semibold">
                    Aleo Setup I
                </AleoTypography>

                <AleoTypography textStyle="body" style={{ maxWidth: 620 }}>
                    The first step in launching Aleo is generating the public parameters for zero-knowledge proofs at
                    the core of the system. To generate those parameters we are running a multi-party computation (MPC)
                    ceremony where even a single honest contributor guarantees the security of the overall system.
                </AleoTypography>

                <AleoTypography textStyle="body" style={{ maxWidth: 620 }}>
                    Everyone can contribute to the security of the network at this point. With your help, we can cement
                    the foundation of trust upon which Aleo will be built.
                </AleoTypography>
            </Col>

            <RightAlignCol lg={10} md={12} xs={24}>
                <StatisticCard>
                    <Tabs size="small">
                        <Tabs.TabPane key="1" tab="Universal Setup">
                            <AleoTypography textStyle="subtitle" noMargin>
                                Has been running for
                            </AleoTypography>
                            <Countdown from={new Date('2021/8/17')} />

                            <AleoTypography
                                textStyle="footnote"
                                type="secondary"
                                style={{ textAlign: 'center', maxWidth: 250, margin: 'auto' }}
                            >
                                We are no longer accepting submissions for the Universal Setup Ceremony.
                            </AleoTypography>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="Outer Setup">
                            <AleoTypography textStyle="subtitle">Ends in:</AleoTypography>
                            <Countdown to={new Date('2021/12/1')} />
                            <AleoTypography
                                textStyle="footnote"
                                type="secondary"
                                style={{ textAlign: 'center', maxWidth: 250, margin: 'auto' }}
                            >
                                We are no longer accepting submissions for the Outer Setup Ceremony.
                            </AleoTypography>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="3" tab="Inner Setup">
                            <AleoTypography textStyle="subtitle">Ends in:</AleoTypography>
                            <Countdown to={new Date('2021/12/1')} />
                            <AleoTypography
                                textStyle="footnote"
                                type="secondary"
                                style={{ textAlign: 'center', maxWidth: 250, margin: 'auto' }}
                            >
                                We are no longer accepting submissions for the Inner Setup Ceremony.
                            </AleoTypography>
                        </Tabs.TabPane>
                    </Tabs>
                </StatisticCard>
            </RightAlignCol>
        </Row>
    );
};

export default Heading;
