export const getFormattedTime = (time: number): { value: number; unit: string } => {
    if (time < 60) {
        return {
            value: time,
            unit: 'Seconds'
        };
    }

    if (time > 60 && time < 3600) {
        return {
            value: +(time / 60).toPrecision(3),
            unit: 'Minutes'
        };
    }

    return {
        value: +(time / 60 / 60).toPrecision(3),
        unit: 'Hours'
    };
};

export const normalizeTranscriptSizes = (
    outerTranscript: string,
    universalTranscript: string,
    innerTranscript: string
): string => {
    const outerTranscriptSize = +outerTranscript.split(' ')[0];
    const outerTranscriptUnit = outerTranscript.split(' ')[1];

    const universalTranscriptSize = +universalTranscript.split(' ')[0];
    const universalTranscriptUnit = universalTranscript.split(' ')[1];

    const innerTranscriptSize = +innerTranscript.split(' ')[0];
    const innerTranscriptUnit = innerTranscript.split(' ')[1];

    if (outerTranscriptUnit === universalTranscriptUnit && outerTranscriptUnit === innerTranscriptUnit) {
        return `${(outerTranscriptSize + universalTranscriptSize + innerTranscriptSize).toFixed(
            2
        )} ${outerTranscriptUnit}`;
    } else {
        if (outerTranscriptUnit === 'TB') {
            return `${(outerTranscriptSize + (universalTranscriptSize + innerTranscriptSize) / 1000).toFixed(
                2
            )} ${outerTranscriptUnit}`;
        } else if (universalTranscriptUnit === 'TB') {
            return `${(universalTranscriptSize + (outerTranscriptSize + innerTranscriptSize) / 1000).toFixed(
                2
            )} ${universalTranscriptUnit}`;
        } else if (innerTranscriptUnit === 'TB') {
            return `${(innerTranscriptSize + (outerTranscriptSize + universalTranscriptSize) / 1000).toFixed(
                2
            )} ${innerTranscriptUnit}`;
        } else {
            throw new Error('Unsupported transcript size');
        }
    }
};
