import React, { useEffect, useState } from 'react';
import { Input, Table } from 'antd';
import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';
import { AleoTypography } from '@aleohq/components';
import { SearchOutlined } from '@ant-design/icons';
import { InternalLink, TableHeader } from '../common';
import { TwitterLinkIcon } from '../Icons';
import { getFormattedTime } from '../../lib/time';
import { CeremonyType, Contributor, useStats } from '../../api/stats';

interface AttestationProps {
    simple?: boolean;
    filter?: CeremonyType;
}

interface AttestationItem {
    id: string;
    contributor: string;
    type: string;
    time: string;
    duration: number;
    attestation: string;
}

const TableWrapper = styled.div`
    margin-top: 80px;
`;

const EmptyWrapper = styled.div`
    background-color: ${palette.gray3};
    border-radius: 0 0 20px 20px;
    padding: 80px 35px;
    margin-bottom: 40px;
    text-align: center;
`;

const StyledTable = styled(Table)<{ simple?: boolean }>`
    background: ${palette.gray3};
    border-radius: 0 0 20px 20px;
    padding: 35px;
    margin-bottom: 40px;

    @media (max-width: 800px) {
        padding: 5px;
    }

    .ant-table {
        background-color: ${palette.gray3};
        max-height: ${(props) => (props.simple ? '350px' : '750px')};
        overflow-y: ${(props) => (props.simple ? 'hidden' : 'scroll')};

        @media (max-width: 800px) {
            overflow-x: scroll;
        }
    }

    .ant-table-cell {
        background: ${palette.gray3};
        border: none;
        color: #ffffff;
        font-size: 16px;
    }

    .ant-table-thead th.ant-table-column-has-sorters {
        background: none;
    }

    .ant-table-thead .ant-table-cell {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        color: ${palette.gray9};
    }

    .ant-table-tbody > tr.ant-table-row > td {
        &:first-child {
            border-radius: 20px 0 0 20px;
        }

        &:last-child {
            border-radius: 0 20px 20px 0;
        }
    }
`;

const TwitterLink = styled.a`
    color: ${palette.gray10};
    stroke: ${palette.gray9};
    transition: all 0.3s;

    &:hover {
        stroke: ${palette.green6};
        color: ${palette.green6};
    }
`;

const SearchField = styled(Input)`
    max-width: 400px;

    @media (max-width: 800px) {
        max-width: 200px;
    }
`;

const AttestationsTable = (props: AttestationProps) => {
    const { stats, isLoading } = useStats(props.filter ?? 'all');
    const [dataSource, setDataSource] = useState<AttestationItem[]>([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (stats?.contributorData) {
            const map = (data: Contributor, i: number) => {
                const time = getFormattedTime(data.roundCompleteTime - data.roundStartTime);

                return {
                    id: (i + 1).toString(10),
                    contributor: data.attestationLink
                        ? '@' + data.attestationLink.split('//')[1].split('/')[1]
                        : 'Anonymous',
                    type: data.setupType,
                    time: `${time.value} ${time.unit}`,
                    duration: data.roundCompleteTime - data.roundStartTime,
                    attestation: data.attestationLink
                };
            };

            if (props.simple) {
                setDataSource(
                    stats?.contributorData
                        .sort((a, b) => {
                            return b.roundCompleteTime - a.roundCompleteTime;
                        })
                        .slice(0, 5)
                        .map(map)
                );
            } else {
                setDataSource(
                    stats?.contributorData
                        .filter((data) => {
                            const contributor = data.attestationLink
                                ? data.attestationLink.split('//')[1].split('/')[1]
                                : 'Anonymous';

                            return contributor.toLowerCase().includes(filter.toLowerCase());
                        })
                        .map(map)
                );
            }
        }
    }, [stats, filter, props]);

    const columns: any = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Contributors',
            dataIndex: 'contributor',
            key: 'contributor',
            render: (data: string) =>
                data === 'Anonymous' ? (
                    data
                ) : (
                    <TwitterLink href={`https://twitter.com/${data}`} target="_blank" rel="noreferrer">
                        {data}
                    </TwitterLink>
                ),
            sorter: (a: AttestationItem, b: AttestationItem) => {
                if (a.contributor < b.contributor) {
                    return -1;
                }
                if (a.contributor > b.contributor) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 280,
            render: (data: string) => `${data.substr(0, 1).toUpperCase()}${data.substr(1)}`,
            sorter: (a: AttestationItem, b: AttestationItem) => {
                if (a.type < b.type) {
                    return -1;
                }
                if (a.type > b.type) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            sorter: (a: AttestationItem, b: AttestationItem) => a.duration - b.duration
        },
        {
            title: <div style={{ textAlign: 'right' }}>Attestation</div>,
            dataIndex: 'attestation',
            key: 'attestation',
            render: (data: string) => (
                <div style={{ textAlign: 'right', paddingRight: 60 }}>
                    {data && (
                        <TwitterLink href={data} target="_blank" rel="noreferrer">
                            <TwitterLinkIcon />
                        </TwitterLink>
                    )}
                </div>
            )
        }
    ];

    return (
        <TableWrapper>
            <TableHeader>
                <AleoTypography textStyle="body" mobileTextStyle="body-small" style={{ margin: 0 }}>
                    {props.simple ? 'Latest Contributions' : 'Attestations'}
                </AleoTypography>

                <div style={{ flex: 1 }} />

                {!props.simple && (
                    <SearchField
                        size="large"
                        placeholder="Search contributors"
                        suffix={<SearchOutlined />}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                )}

                {props.simple && (
                    <InternalLink to="/stats" target="_blank" rel="noreferrer">
                        View All
                    </InternalLink>
                )}
            </TableHeader>

            {dataSource.length > 0 ? (
                <StyledTable
                    rowKey="id"
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    pagination={false}
                    simple={props.simple}
                />
            ) : (
                <EmptyWrapper>
                    <AleoTypography textStyle="body" type="secondary" noMargin>
                        There are no records to display.
                    </AleoTypography>
                </EmptyWrapper>
            )}
        </TableWrapper>
    );
};

export default AttestationsTable;
