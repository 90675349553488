import React, { useState } from 'react';
import { AleoTypography } from '@aleohq/components';
import styled from 'styled-components';
import { Card, Radio, Spin } from 'antd';
import { AttestationsTable, FilterButtons, FilterButtonWrapper } from '../components';
import ContributionGraph from '../components/ContributionGraph/ContributionGraph';
import { useStats } from '../api/stats';

interface StatCardProps {
    value: string | number;
    unit: string;
    label: string;
    loading?: boolean;
}

type FilterBy = 'inner' | 'outer' | 'universal' | 'all';

const StatRow = styled.div`
    display: flex;
    margin-top: 80px;
    margin-bottom: 40px;
    gap: 30px;

    @media (max-width: 800px) {
        margin-top: 40px;
        flex-direction: column;
    }
`;

const StyledStatCard = styled(Card)`
    background: transparent;
    max-width: 255px;

    @media (max-width: 800px) {
        max-width: 100%;
    }

    .ant-card-body {
        padding: 20px;
    }
`;

const StatCard = (props: StatCardProps) => (
    <StyledStatCard loading={props.loading}>
        <AleoTypography textStyle="heading-3" weight="semibold" noMargin>
            <span className="primary-text">{props.value}</span> {props.unit}
        </AleoTypography>

        <AleoTypography textStyle="body" noMargin>
            {props.label}
        </AleoTypography>
    </StyledStatCard>
);

const Stats = () => {
    const [filterBy, setFilterBy] = useState<FilterBy>('all');
    const { stats, isLoading } = useStats(filterBy);

    return (
        <Spin spinning={isLoading}>
            <AleoTypography textStyle="title" mobileTextStyle="heading-2" weight="semibold">
                Setup Stats
            </AleoTypography>
            <AleoTypography textStyle="body" style={{ maxWidth: 550 }}>
                Contributors to the Aleo setup are generating the parameters for our network. If even a single
                contributor follows the protocol, the system is secure. This is a community effort, and we're thankful
                to everyone who participated. See the stats for our ceremonies below.
            </AleoTypography>

            <FilterButtonWrapper>
                <AleoTypography textStyle="body" noMargin>
                    Filter by:{' '}
                </AleoTypography>

                <FilterButtons
                    defaultValue="all"
                    size="large"
                    onChange={(e) => setFilterBy(e.target.value)}
                    value={filterBy}
                >
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="universal">Universal Setup</Radio.Button>
                    <Radio.Button value="outer">Outer Setup</Radio.Button>
                    <Radio.Button value="inner">Inner Setup</Radio.Button>
                </FilterButtons>
            </FilterButtonWrapper>

            {stats && (
                <>
                    <StatRow>
                        <StatCard
                            loading={isLoading}
                            value={stats.contributorData.length}
                            unit="People"
                            label="Total Participants to Date"
                        />
                        <StatCard
                            loading={isLoading}
                            value={stats.transcriptSize}
                            unit={stats.transcriptUnit}
                            label="Contributions to Date"
                        />
                        <StatCard
                            loading={isLoading}
                            value={stats.averageContributionTime}
                            unit={stats.averageContributionUnit}
                            label="Average Contribution Time"
                        />
                    </StatRow>

                    <ContributionGraph contributors={stats.contributorData} setupType={filterBy} />

                    <AttestationsTable filter={filterBy} />
                </>
            )}
        </Spin>
    );
};

export default Stats;
