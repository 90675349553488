import { axios } from './client';
import { AxiosResponse } from 'axios';

export interface LoginRequest {
    email_username: string;
    password: string;
}

export interface LoginResponse {
    token: string;
}

export interface RegisterRequest {
    email: string;
    name?: {
        first_name: string;
        last_name: string;
    };
    password: string;
    username: string;
}

export interface RegisterResponse {
    account_id: string;
}

export interface UserProfile {
    account_id: string;
    bio: string | null;
    created_at: Date;
    email: string;
    email_verified: boolean | null;
    github: string | null;
    last_online: Date;
    name: {
        first_name: string;
        last_name: string;
    };
    twitter: string | null;
    username: string;
    followers: number;
    following: number;
}

const API_BASE =
    process.env.NODE_ENV === 'development' ? 'https://apm-backend-dev.herokuapp.com/v1' : 'https://aleo.pm/v1';

export const loginRequest = (data: LoginRequest): Promise<AxiosResponse<LoginResponse>> => {
    return axios.post(API_BASE + '/account/login', data);
};

export const registerRequest = (data: RegisterRequest): Promise<AxiosResponse<RegisterResponse>> => {
    return axios.post(API_BASE + '/account/signup', data);
};

export const currentUserRequest = async (): Promise<AxiosResponse<UserProfile>> => {
    const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('apm-token')
    };

    const currentUser = await axios.get(API_BASE + '/account/my_profile', { headers });
    const followers = await axios.get(API_BASE + '/followers/' + currentUser.data.account_id, { headers });
    const following = await axios.get(API_BASE + '/followings/' + currentUser.data.account_id, { headers });

    return {
        ...currentUser,
        data: {
            ...currentUser.data,
            followers: followers.data.length,
            following: following.data.length
        }
    };
};
