import React from 'react';
import styled from 'styled-components';
import { Logo, RightAlignCol } from '../common';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';
import { DiscordIcon, GitHubIcon, TwitterIcon } from '../Icons';

const FooterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

const SloganText = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 12px;
        line-height: 20px;
        color: ${palette.gray9};
        margin: 0 0 8px;
    }
`;

const BottomText = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 12px;
        line-height: 26px;
        color: ${palette.gray9};
        text-align: center;
        font-weight: 600;
    }
`;

const FooterDivider = styled(Divider)`
    &.ant-divider {
        border-color: ${palette.gray9};
        margin: 7px 0;
    }
`;

const Footer = () => (
    <FooterWrapper>
        <a href="https://aleo.org" target="_blank" rel="noreferrer">
            <Logo />
        </a>

        <Row style={{ marginTop: 14 }}>
            <Col span={12}>
                <SloganText>A Platform for Interoperable, Confidential Programs.</SloganText>
            </Col>

            <RightAlignCol span={12}>
                <Space size={18}>
                    <a href="https://twitter.com/aleohq" target="_blank" rel="noreferrer">
                        <TwitterIcon style={{ color: 'white' }} />
                    </a>
                    <a href="https://github.com/AleoHQ" target="_blank" rel="noreferrer">
                        <GitHubIcon style={{ color: 'white' }} />
                    </a>
                    <a href="https://discord.com/invite/SMXsDEQ" target="_blank" rel="noreferrer">
                        <DiscordIcon style={{ color: 'white' }} />
                    </a>
                </Space>
            </RightAlignCol>
        </Row>

        <FooterDivider />

        <BottomText>
            Aleo Systems Inc. Your Privacy is{' '}
            <a href="https://aleo.org/privacy" target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                Protected
            </a>
            .
        </BottomText>
    </FooterWrapper>
);

export default Footer;
