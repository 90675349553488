import React from 'react';
import { BackUpButtons, BackUpContent, BackUpProps, BackUpWrapper, ButtonRow } from '../common';
import { palette } from '@aleohq/ui/dist/palette';
import { Button, Modal, notification } from 'antd';
import styled from 'styled-components';
import { AleoTypography } from '@aleohq/components';
import { useAppSelector } from '../../../store';
import { saveAs } from 'file-saver';

const List = styled.ul`
    padding-inline-start: 15px;
    margin-top: 25px;

    li {
        margin-bottom: 20px;
    }

    li::marker {
        color: ${palette.green6};
    }
`;

const KeyWrapper = styled.div`
    margin-top: 65px;
    margin-bottom: 20px;
    border-top: 1px solid ${palette.gray4};
    border-bottom: 1px solid ${palette.gray4};
    padding: 30px 40px;
`;

const BackUpPrivateKeyOnly = (props: BackUpProps) => {
    const { privateKey, address } = useAppSelector((state) => state.wallet);

    const copy = () => {
        if (privateKey && address) {
            navigator.clipboard
                .writeText(`Private Key: ${privateKey}\n\nAddress: ${address}`)
                .then(() => {
                    notification.info({
                        message: 'Copied!',
                        description: 'Your private key has been copied to your clipboard.'
                    });
                })
                .catch(() => {
                    notification.error({
                        message: 'Uh oh!',
                        description: 'Could not copy the private key to your clipboard.'
                    });
                });
        }
    };

    const download = () => {
        if (privateKey && address) {
            const blob = new Blob(
                [
                    JSON.stringify({
                        address,
                        privateKey
                    })
                ],
                { type: 'application/json' }
            );

            saveAs(blob, 'backup.json');
        }
    };

    const confirmContinue = () => {
        Modal.confirm({
            title: <AleoTypography textStyle="subtitle">Attention!</AleoTypography>,
            content: (
                <List>
                    <li>
                        <AleoTypography textStyle="body">
                            Do not lose it. Your private key cannot be recovered if you lose it.
                        </AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">Do not screenshot it.</AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">
                            Write it down on paper and hide it somewhere, put it in a safety deposit box, or use a
                            secure password manager.
                        </AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">
                            The Aleo Team cannot recover your account if you lose your private key.
                        </AleoTypography>
                    </li>
                </List>
            ),
            centered: true,
            icon: null,
            closable: true,
            maskClosable: true,
            width: '415px',
            okButtonProps: {
                size: 'large'
            },
            cancelButtonProps: {
                size: 'large',
                ghost: true
            },
            cancelText: 'Hold on',
            okText: 'I Understand',
            className: 'setup-modal',
            onOk: () => props.onNext('SUCCESS')
        });
    };

    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3" style={{ width: 500 }}>
                    Back Up using Private Key
                </AleoTypography>

                <AleoTypography textStyle="body" style={{ maxWidth: 500 }}>
                    Please copy your private keystring and store it in a safe place. It will allow you to recover your
                    account.
                </AleoTypography>

                <KeyWrapper>
                    <AleoTypography textStyle="subtitle" weight="semibold">
                        Private Key
                    </AleoTypography>
                    <AleoTypography textStyle="body" style={{ marginBottom: 20 }}>
                        {privateKey}
                    </AleoTypography>

                    <AleoTypography textStyle="subtitle" weight="semibold">
                        Address
                    </AleoTypography>
                    <AleoTypography textStyle="body" noMargin>
                        {address}
                    </AleoTypography>
                </KeyWrapper>

                <ButtonRow>
                    <Button size="large" type="primary" onClick={copy}>
                        Copy
                    </Button>
                    <AleoTypography textStyle="body" noMargin>
                        or
                    </AleoTypography>
                    <Button size="large" onClick={download}>
                        Download
                    </Button>
                </ButtonRow>
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={() => props.onBack()}>
                    Back
                </Button>

                <Button type="primary" size="large" onClick={() => confirmContinue()}>
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default BackUpPrivateKeyOnly;
