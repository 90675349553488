import React from 'react';
import { ErrorGraphic, ErrorInfoWrapper, ErrorWrapper } from '../components';
import { AleoTypography } from '@aleohq/components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const Error500 = () => {
    return (
        <ErrorWrapper>
            <ErrorInfoWrapper>
                <AleoTypography textStyle="title" weight="semibold">
                    500 <span className="primary-text">Server Error</span>
                </AleoTypography>
                <AleoTypography textStyle="body">
                    The server encountered an internal error
                    <br />
                    and was unable to complete your request.
                </AleoTypography>

                <Link to="/">
                    <Button type="primary" size="large">
                        Go Home
                    </Button>
                </Link>
            </ErrorInfoWrapper>

            <ErrorGraphic />
        </ErrorWrapper>
    );
};

export default Error500;
