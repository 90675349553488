import { AleoTypography } from '@aleohq/components';
import { palette } from '@aleohq/ui/dist/palette';
import { Button, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTweetText, getTwitterAuth, postTweet } from '../../api/ceremony';
import { useAppSelector } from '../../store';
import { ButtonRow, CommonProps } from './common';

const Tweet = styled.div`
    background-color: ${palette.gray3};
    border: 1px solid ${palette.green6};
    border-radius: 20px;
    padding: 20px;
    min-width: 500px;
    min-height: 110px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
`;

const PinWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
`;

const Step5Tweet = (props: CommonProps) => {
    const wallet = useAppSelector((state) => state.wallet);
    const [showPin, setShowPin] = useState(false);
    const [tweetText, setTweetText] = useState<React.ReactNode>();
    const [auth, setAuth] = useState<{ key: string; secret: string }>();
    const [pin, setPin] = useState('');

    useEffect(() => {
        if (wallet.address) {
            getTweetText(wallet.address)
                .then((res) => {
                    setTweetText(
                        <>
                            {res.data.split('\n').map((line) => (
                                <p key={line}>{line}</p>
                            ))}
                        </>
                    );
                })
                .catch((e) => {
                    console.error(e.response.data);
                    if (e.response && e.response.data) {
                        notification.error({
                            message: 'Uh oh!',
                            description: e.response.data
                        });
                    } else {
                        notification.error({
                            message: 'Uh oh!',
                            description: 'There was an error fetching the tweet content. Please contact us on Discord.'
                        });
                    }
                });
        }
    }, [wallet]);

    const authorize = async () => {
        try {
            const twitterAuth = await getTwitterAuth();

            window.open('https://api.twitter.com/oauth/authorize?oauth_token=' + twitterAuth.data.key, '_blank');

            setShowPin(true);
            setAuth({
                key: twitterAuth.data.key,
                secret: twitterAuth.data.secret
            });
        } catch (e) {
            console.error(e.response.data);
            if (e.response && e.response.data) {
                notification.error({
                    message: 'Uh oh!',
                    description: e.response.data
                });
            } else {
                notification.error({
                    message: 'Uh oh!',
                    description: 'There was an error fetching the tweet content. Please contact us on Discord.'
                });
            }
        }
    };

    const tweet = () => {
        if (auth && pin && wallet.address) {
            postTweet({
                address: wallet.address,
                twitter_info: {
                    requestToken: {
                        key: auth.key,
                        secret: auth.secret
                    },
                    pin: pin
                }
            })
                .then(() => {
                    notification.success({
                        message: 'Thank you!',
                        description: 'You have just tweeted your support for our Setup Ceremony!'
                    });

                    props.onNextStep();
                })
                .catch((e) => {
                    console.error(e.response.data);
                    if (e.response && e.response.data) {
                        notification.error({
                            message: 'Uh oh!',
                            description: e.response.data
                        });
                    } else {
                        notification.error({
                            message: 'Uh oh!',
                            description: 'There was an error fetching the tweet content. Please contact us on Discord.'
                        });
                    }
                });
        }
    };

    return (
        <div>
            <AleoTypography textStyle="heading-3" style={{ width: 460 }}>
                Thank you! You've helped make Aleo secure and private.
            </AleoTypography>

            <AleoTypography textStyle="body" style={{ width: 600 }}>
                We appreciate if you could share your participation on Twitter by tweeting the below. First, click the
                Authorize button. This will open a new tab where you can securely log into Twitter. After you log in,
                you will be provided with a single use code that allows us to post a Tweet on your behalf. The contents
                of the Tweet are displayed below.
            </AleoTypography>

            <AleoTypography textStyle="body" style={{ width: 600 }}>
                If you don't want to tweet, you're done! Your contribution has been recorded and you are free to close
                the tab.
            </AleoTypography>

            <Tweet>{tweetText}</Tweet>

            {!showPin && (
                <Button type="primary" size="large" onClick={authorize}>
                    Authorize
                </Button>
            )}

            {showPin && (
                <PinWrapper>
                    <label>
                        Twitter Auth PIN
                        <Input
                            size="large"
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                            placeholder="1234567"
                            required
                        />
                    </label>

                    <ButtonRow style={{ marginTop: 10 }}>
                        <Button type="primary" size="large" onClick={tweet} disabled={!pin || pin.length !== 7}>
                            Tweet
                        </Button>

                        <AleoTypography textStyle="body" noMargin>
                            or
                        </AleoTypography>

                        <Button size="large" onClick={authorize}>
                            Re-authorize
                        </Button>
                    </ButtonRow>
                </PinWrapper>
            )}
        </div>
    );
};

export default Step5Tweet;
