import React, { ErrorInfo } from 'react';
import { ErrorGraphic, ErrorInfoWrapper, ErrorWrapper } from './common';
import { AleoTypography } from '@aleohq/components';
import { Button } from 'antd';
import styled from 'styled-components';

const StyledBoundary = styled.div`
    height: 100vh;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    margin: auto;
`;

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        console.log('updating error');
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Do some logging maybe?
        console.log('This is error');
    }

    render() {
        if (this.state.hasError) {
            return (
                <StyledBoundary>
                    <ErrorWrapper>
                        <ErrorInfoWrapper>
                            <AleoTypography textStyle="title" weight="semibold">
                                <span className="primary-text">Oops!</span>
                            </AleoTypography>
                            <AleoTypography textStyle="body">
                                Sorry about that, but the Setup Ceremony has crashed.
                                <br />
                                We'd love it if you tried again!
                            </AleoTypography>

                            <a href="/">
                                <Button type="primary" size="large">
                                    Go Home
                                </Button>
                            </a>
                        </ErrorInfoWrapper>

                        <ErrorGraphic />
                    </ErrorWrapper>
                </StyledBoundary>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
