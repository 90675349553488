import { Tree, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { AleoTypography } from '@aleohq/components';

interface TreeNode {
    title: string;
    key: string;
    isFile?: boolean;
    children?: TreeNode[];
}

interface FileOrFolder {
    [key: string]: FileOrFolder | { __isFile: boolean };
}

function createTreeData(files: string[]): TreeNode[] {
    const tree: FileOrFolder = {};

    files.forEach((filePath) => {
        const parts = filePath.split('/');
        let currentLevel: FileOrFolder | { __isFile: boolean } = tree;

        parts.forEach((part, i) => {
            if (!('__isFile' in currentLevel)) {
                const folderLevel = currentLevel as FileOrFolder;

                if (!folderLevel[part]) {
                    folderLevel[part] = {};
                }

                if (i === parts.length - 1) {
                    folderLevel[part] = { __isFile: true };
                } else {
                    currentLevel = folderLevel[part] as FileOrFolder;
                }
            }
        });
    });

    const createNodes = (obj: FileOrFolder, path = ''): TreeNode[] => {
        return Object.keys(obj).map((key) => {
            const newPath = path ? `${path}/${key}` : key;

            if (obj[key].__isFile) {
                return { title: key, key: newPath, isFile: true };
            }

            const folder = obj[key] as FileOrFolder;
            return {
                title: key,
                key: newPath,
                children: createNodes(folder, newPath)
            };
        });
    };

    return createNodes(tree);
}

const Transcripts: React.FC = () => {
    const [files, setFiles] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetch('transcripts.txt')
            .then((response) => response.text())
            .then((data) => {
                const fileArray = data.split('\n').filter((file) => file.trim() !== '');
                setFiles(fileArray);
                setLoading(false); // Update loading state after fetching
            })
            .catch((error) => {
                console.error('Error fetching the file list:', error);
                setLoading(false); // Update loading state even on error
            });
    }, []);

    const treeData = createTreeData(files);

    const onDownload = (key: string, isFile: boolean) => {
        if (isFile) {
            const link = document.createElement('a');
            link.href = `https://storage.googleapis.com/setup-storage/transcripts/${key}`;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const onSelect = (selectedKeys: React.Key[], info: any) => {
        if (info.selectedNodes[0]) {
            const { key, isFile } = info.selectedNodes[0];
            onDownload(key, !!isFile);
        }
    };

    const containerStyle: React.CSSProperties = {
        border: '1px solid #272935',
        borderRadius: '20px',
        padding: '20px',
        marginTop: '20px',
        backgroundColor: '#1E2029'
    };

    return (
        <>
            <AleoTypography textStyle="title" weight="semibold">
                Transcripts
            </AleoTypography>
            <AleoTypography textStyle="body" style={{ maxWidth: 550 }}>
                Files may take a second to load. Click on a file to download it.
            </AleoTypography>
            <div style={containerStyle}>
                {loading ? (
                    <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
                ) : (
                    <Tree
                        showLine={true}
                        showIcon={true}
                        treeData={treeData}
                        onSelect={onSelect}
                        height={window.innerHeight / 2}
                    />
                )}
            </div>
        </>
    );
};

export default Transcripts;
