import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const Wrapper = styled.div`
    padding: 10px;
    margin-bottom: 180px;
`;

const Title = styled(Typography.Title)`
    &.ant-typography {
        font-size: 56px;
        line-height: 64px;
        color: white;
    }
`;

const Subheader = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 16px;
        line-height: 24px;
        color: white;
        max-width: 430px;
    }
`;

const DesktopOnly = () => (
    <Wrapper>
        <Title>Aleo Setup I</Title>

        <Subheader>The Aleo Setup can only be accessed on a desktop browser.</Subheader>
    </Wrapper>
);

export default DesktopOnly;
