import React from 'react';
import { Card, Col, Divider, Row } from 'antd';
import { AleoTypography } from '@aleohq/components';
import { AttestationsTable, ExternalLink, RightAlignCol } from '../components';
import styled from 'styled-components';
import { useStats } from '../api/stats';

const StatisticCard = styled(Card)`
    border: none;

    @media (max-width: 600px) {
        width: 100%;
    }

    .ant-card-body {
        min-width: 350px;
        padding: 40px;
        box-sizing: border-box;

        @media (max-width: 600px) {
            min-width: 100%;
            padding: 30px 25px;
        }
    }
`;

const SetupFinished = () => {
    const { stats, isLoading } = useStats('all');

    const getMostContributions = () => {
        if (stats) {
            let universalCount = 0;
            let outerCount = 0;
            let innerCount = 0;

            for (const contrib of stats.contributorData) {
                if (contrib.setupType === 'universal') {
                    universalCount++;
                } else if (contrib.setupType === 'outer') {
                    outerCount++;
                } else if (contrib.setupType === 'inner') {
                    innerCount++;
                }
            }

            const max = Math.max(universalCount, outerCount, innerCount);

            if (max === universalCount) {
                return 'Universal Setup';
            } else if (max === outerCount) {
                return 'Outer Setup';
            } else if (max === innerCount) {
                return 'Inner Setup';
            }
        } else {
            return 'Loading...';
        }
    };

    return (
        <>
            <Row gutter={[8, 40]}>
                <Col lg={14} md={12} xs={24}>
                    <AleoTypography
                        textStyle="title"
                        mobileTextStyle="heading-2"
                        weight="semibold"
                        style={{ maxWidth: 410 }}
                    >
                        The Ceremony is completed.
                    </AleoTypography>

                    <AleoTypography textStyle="body" style={{ maxWidth: 500 }}>
                        Thank you for your contribution! Please stay tuned for our upcoming Phase 2 Outer and Inner
                        setups. The best way to receive updates is through Aleo’s{' '}
                        <ExternalLink href="https://discord.com/invite/SMXsDEQ">Official Discord Server</ExternalLink>.
                    </AleoTypography>
                </Col>

                <RightAlignCol lg={10} md={12} xs={24}>
                    <StatisticCard loading={isLoading}>
                        <AleoTypography textStyle="subtitle">Total Contributors</AleoTypography>
                        <AleoTypography textStyle="heading-3" weight="semibold" type="primary">
                            {stats?.contributorData.length}
                        </AleoTypography>

                        <Divider />

                        <AleoTypography textStyle="subtitle">Most Contributed Ceremony</AleoTypography>
                        <AleoTypography textStyle="heading-3" weight="semibold" type="primary">
                            {getMostContributions()}
                        </AleoTypography>

                        <Divider />

                        <AleoTypography textStyle="subtitle">Average Contribution Time</AleoTypography>
                        <AleoTypography textStyle="heading-3" weight="semibold" type="primary">
                            {stats?.averageContributionTime} {stats?.averageContributionUnit}
                        </AleoTypography>
                    </StatisticCard>
                </RightAlignCol>
            </Row>

            <AttestationsTable />
        </>
    );
};

export default SetupFinished;
