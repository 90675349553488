import styled from 'styled-components';
import { Form, ModalProps, Typography } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';

export interface AuthModalProps extends ModalProps {
    onAuth: () => void;
}

export const ModalTitle = styled(Typography.Title)`
    &.ant-typography {
        font-size: 34px;
        color: #ffffff;
    }
`;

export const HelpText = styled(Typography.Paragraph)`
    font-size: 16px;
    color: ${palette.gray9};
    margin-top: 20px;
`;

export const SignInForm = styled(Form)`
    margin-top: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FormWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
    text-align: center;
    margin-bottom: 60px;
`;
