import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProps } from './types';

export const MinusSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 12L6 12" stroke="#B2B6C7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const MinusIcon = (props: CustomIconProps) => <Icon component={MinusSvg} {...props} />;
