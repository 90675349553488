import React from 'react';
import AuthModal from './AuthModal';
import { Button, Form, Input, notification, Typography } from 'antd';
import styled from 'styled-components';
import { UserIcon } from '../Icons';
import { ExternalLink, OrionIcon, TextButton } from '../common';
import { palette } from '@aleohq/ui/dist/palette';
import background from '../../assets/visual-gradient.png';
import { AuthModalProps, ButtonWrapper, FormWrapper, HelpText, ModalTitle, SignInForm } from './common';
import { setSignIn, setSignUp } from '../../store/modal.slice';
import { LoginRequest } from '../../api/user';
import useAuth, { SignInData } from '../../hooks/use-auth';
import { useAppDispatch } from '../../store';

const ResetPasswordText = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 14px;
        color: ${palette.gray9};
    }
`;

const SignInModal = (props: AuthModalProps) => {
    const dispatch = useAppDispatch();
    const { userLoading, login } = useAuth();
    const [form] = Form.useForm<LoginRequest>();

    const signUp = () => {
        dispatch(setSignIn(false));
        dispatch(setSignUp(true));
    };

    const signIn = async (data: SignInData) => {
        try {
            await login(data);
            form.resetFields();
            props.onAuth();
            dispatch(setSignIn(false));
        } catch (e) {
            notification.error({
                message: 'Error signing in!',
                description: e.toString()
            });
        }
    };

    return (
        <AuthModal
            footer={null}
            {...props}
            graphicTitle="Welcome back,"
            graphicSubtitle="Sign in to your account to participate in the Aleo Setup."
            background={background}
            closable
        >
            <ModalTitle>Sign In</ModalTitle>

            <SignInForm form={form} onFinish={signIn as any}>
                <FormWrapper>
                    <Form.Item name="username" rules={[{ required: true, message: 'Username is required!' }]}>
                        <Input placeholder="Username" prefix={<UserIcon style={{ width: 18 }} />} size="large" />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: 'Password is required!' }]}>
                        <Input
                            placeholder="Password"
                            type="password"
                            prefix={<OrionIcon icon="lock" fontSize={16} />}
                            size="large"
                        />
                    </Form.Item>

                    <ResetPasswordText>
                        Forgot your password?{' '}
                        <ExternalLink href="https://aleo.pm/forgot_password">Reset your password</ExternalLink>.
                    </ResetPasswordText>
                </FormWrapper>

                <Form.Item style={{ marginBottom: 0 }}>
                    <ButtonWrapper>
                        <Button type="primary" size="large" loading={userLoading} htmlType="submit">
                            Sign In
                        </Button>
                    </ButtonWrapper>

                    <HelpText>
                        Don't have an account? <TextButton onClick={signUp}>Sign up here</TextButton>.
                    </HelpText>
                </Form.Item>
            </SignInForm>
        </AuthModal>
    );
};

export default SignInModal;
