import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProps } from './types';

const TwitterSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23 5.13818C22.191 5.48965 21.3105 5.74261 20.4037 5.84113C21.3452 5.2778 22.0503 4.38782 22.3866 3.33818C21.5031 3.8675 20.5352 4.23866 19.5259 4.43522C19.104 3.98103 18.5938 3.6192 18.0271 3.37228C17.4603 3.12536 16.8491 2.99864 16.2316 3.00001C13.7331 3.00001 11.7237 5.03965 11.7237 7.54261C11.7237 7.89409 11.766 8.24557 11.8348 8.58374C8.09362 8.38669 4.757 6.5867 2.53876 3.83078C2.13457 4.52606 1.92276 5.31771 1.92537 6.12338C1.92537 7.69971 2.72119 9.08965 3.93474 9.90711C3.21958 9.87874 2.52116 9.68077 1.89629 9.3293V9.38521C1.89629 11.5926 3.44562 13.4219 5.51052 13.8426C5.12281 13.944 4.72397 13.9959 4.3234 13.997C4.02992 13.997 3.75231 13.9678 3.47206 13.9278C4.04314 15.7278 5.70616 17.0352 7.68646 17.0778C6.13712 18.3 4.19649 19.0189 2.08929 19.0189C1.71121 19.0189 1.36222 19.0056 1 18.963C2.9988 20.2544 5.37039 21 7.92441 21C16.2157 21 20.7527 14.0823 20.7527 8.07782C20.7527 7.88078 20.7527 7.68374 20.7395 7.48669C21.6172 6.83965 22.3866 6.03817 23 5.13818Z"
            fill="currentColor"
        />
    </svg>
);

export const TwitterIcon = (props: CustomIconProps) => <Icon component={TwitterSvg} {...props} />;
