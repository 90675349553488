import React from 'react';
import { ErrorGraphic, ErrorInfoWrapper, ErrorWrapper } from '../components';
import { AleoTypography } from '@aleohq/components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const Error404 = () => {
    return (
        <ErrorWrapper>
            <ErrorInfoWrapper>
                <AleoTypography textStyle="title" weight="semibold">
                    404 <span className="primary-text">Not Found</span>
                </AleoTypography>
                <AleoTypography textStyle="body">
                    Sorry for the inconvenience,
                    <br />
                    but the page you're looking for does not exist.
                </AleoTypography>

                <Link to="/">
                    <Button type="primary" size="large">
                        Go Home
                    </Button>
                </Link>

                <AleoTypography textStyle="body" style={{ marginTop: 100 }}>
                    If you think this is an error on our part,
                    <br />
                    <Link to="/">please let us know</Link>.
                </AleoTypography>
            </ErrorInfoWrapper>

            <ErrorGraphic />
        </ErrorWrapper>
    );
};

export default Error404;
