import styled from 'styled-components';
import { Col, List, Row, Typography } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';
import { RightAlignCol } from '../common';
import React, { useContext } from 'react';
import { AleoThemeContext, AleoTypography } from '@aleohq/components';

interface CeremonyProps {
    title: string;
    description: React.ReactNode | string;
    stats?: { label: string; value: string }[];
    button: React.ReactNode;
    active?: boolean;
}

const StyledListItem = styled(List.Item)<{ $active?: boolean; $primaryColor: string }>`
    width: 100%;
    min-height: 110px;
    border-radius: 20px;
    padding: 25px 50px;
    background: ${palette.gray3};
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    @media (max-width: 800px) {
        padding: 30px;
    }

    &:hover {
        border-color: ${(props) => (props.$active ? props.$primaryColor : 'transparent')};
    }
`;

const GridTitle = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #ffffff;
    }
`;

const GridValue = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 16px;
        line-height: 24px;
        color: ${palette.gray9};
        margin: 0;
    }
`;

const CeremonyItem = (props: CeremonyProps) => {
    const theme = useContext(AleoThemeContext);

    return (
        <StyledListItem $active={props.active} $primaryColor={theme.primaryColor}>
            <Row gutter={[50, 30]} style={{ flex: 1, alignItems: 'center' }}>
                <Col md={props.stats ? 6 : 20} xs={24}>
                    <AleoTypography
                        textStyle="subtitle"
                        weight="semibold"
                        type={props.active ? 'default' : 'secondary'}
                    >
                        {props.title}
                    </AleoTypography>
                    <AleoTypography textStyle="body" type="secondary" noMargin>
                        {props.description}
                    </AleoTypography>
                </Col>

                {props.stats && (
                    <Col md={14} xs={24}>
                        <Row gutter={50}>
                            {props.stats?.map((stat) => (
                                <Col lg={6}>
                                    <GridTitle>{stat.label}</GridTitle>
                                    <GridValue>{stat.value}</GridValue>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                )}

                <RightAlignCol md={4} xs={24} style={{ alignItems: 'center', height: '100%' }}>
                    {props.button}
                </RightAlignCol>
            </Row>
        </StyledListItem>
    );
};

export default CeremonyItem;
