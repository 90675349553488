import { axios } from './client';
import { AxiosResponse } from 'axios';

interface CeremonyStatusRequest {
    address: string;
}

type CeremonyStatusResponse =
    | string
    | {
          Queue: [number, number];
      };

interface TwitterAuthResponse {
    key: string;
    secret: string;
}

interface PostTweetRequest {
    address: string;
    twitter_info: {
        requestToken: {
            key: string;
            secret: string;
        };
        pin: string;
    };
}

const BASE_URL = process.env.REACT_APP_CEREMONY_URL + '/v1/inner';

export const ceremonyStatus = (data: CeremonyStatusRequest): Promise<AxiosResponse<CeremonyStatusResponse>> => {
    return axios.post(BASE_URL + '/status', data);
};

export const submitEmail = (data: { email: string; address: string }): Promise<AxiosResponse<string>> => {
    return axios.post(BASE_URL + '/add_email', data);
};

export const getTwitterAuth = (): Promise<AxiosResponse<TwitterAuthResponse>> => {
    return axios.get(BASE_URL + '/twitter_link');
};

export const getTweetText = (address: string): Promise<AxiosResponse<string>> => {
    return axios.post(BASE_URL + '/get_tweet_text', { address });
};

export const postTweet = (data: PostTweetRequest): Promise<AxiosResponse<any>> => {
    return axios.post(BASE_URL + '/post_tweet', data);
};
