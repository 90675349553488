import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProps } from './types';

export const PlusSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 20L12.0004 4.00036M19.9993 12.0007L4.00001 12L19.9993 12.0007Z"
            stroke="#B2B6C7"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const PlusIcon = (props: CustomIconProps) => <Icon component={PlusSvg} {...props} />;
