import React, { useEffect, useState } from 'react';
import { AleoTypography } from '@aleohq/components';
import { DeleteCircleIcon, FilterButtons, FilterButtonWrapper } from '../components';
import { Card, Col, Form, Progress, Radio, Result, Row, Select, Spin, Tooltip } from 'antd';
import styled from 'styled-components';
import { gradients, palette } from '@aleohq/ui/dist/palette';
import { useStatus } from '../api/status';
import dayjs from 'dayjs';

type Ceremony = 'universal' | 'outer' | 'inner';

interface RoundInformation {
    round: number;
    currentContributors: string[];
    finishedContributors: string[];
    droppedContributors: string[];
    startedAt: string;
    finishedAt?: string;
    chunksComplete: number;
    percentComplete: number;
    finish_time: {
        value: string;
        unit: string;
    };
}

const CoordinatorStateWrapper = styled.div`
    margin-top: 80px;
`;

const Section = styled.div`
    margin-bottom: 80px;
`;

const Table = styled.table`
    border-radius: 20px;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;

    th {
        font-weight: 600;
        background-color: ${palette.gray3};
        width: 220px !important;
    }

    tr:first-child {
        th,
        td {
            border-top: 1px solid ${palette.gray4};
        }

        th:first-child {
            border-radius: 20px 0 0 0;
        }

        td:last-child {
            border-radius: 0 20px 0 0;
        }
    }

    tr:last-child,
    tr.bottom {
        th:first-child {
            border-radius: 0 0 0 20px;
        }

        td:last-child {
            border-radius: 0 0 20px 0;
        }
    }

    th:first-child,
    td:first-child {
        border-left: 1px solid ${palette.gray4};
    }

    td,
    th {
        padding: 16px;
        border-right: 1px solid ${palette.gray4};
        border-bottom: 1px solid ${palette.gray4};
    }

    .invisible {
        border: none !important;
        background-color: transparent;
        text-align: center;
    }

    &.small-cells {
        table-layout: fixed;
        overflow-x: scroll;

        th,
        td {
            text-align: center;
            width: 65px !important;
        }
    }
`;

const StatRow = styled.div`
    display: flex;
    gap: 20px;
`;

const StatCard = styled(Card)`
    margin-top: 80px;
    display: inline-block;
    background: none;

    .ant-card-body {
        padding: 20px 30px;
        min-width: 250px;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const RoundProgress = styled(Progress)`
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;

    .ant-progress-outer {
        margin: 0;
        padding: 0;
    }

    .ant-progress-text {
        width: unset;
    }

    .ant-progress-bg {
        height: 21px !important;
        background: ${gradients.greenGradient};
    }
`;

const HideMobile = styled.td`
    @media (max-width: 500px) {
        display: none;
    }
`;

const Status = () => {
    const [filterBy, setFilterBy] = useState<Ceremony>('universal');
    const [roundHeight, setRoundHeight] = useState<number | undefined>();
    const [roundInfo, setRoundInfo] = useState<RoundInformation>();

    const { coordinatorData, stateData, stateError, loading } = useStatus(filterBy, roundHeight);

    useEffect(() => {
        setRoundHeight(undefined);
    }, [filterBy]);

    useEffect(() => {
        if (!roundHeight && coordinatorData) {
            setRoundHeight(coordinatorData.current_round_height);
        }
    }, [coordinatorData, roundHeight]);

    useEffect(() => {
        if (coordinatorData && stateData) {
            const chunksComplete = stateData.chunks.filter(
                (c) => c.contributions[1] !== undefined && c.contributions[1].verified
            ).length;
            const percentComplete = (chunksComplete / stateData.chunks.length) * 100;

            const [value, ...unit] = dayjs()
                .to(dayjs().add(coordinatorData.current_metrics.estimated_finish_time, 'seconds'), true)
                .split(' ');

            if (coordinatorData.current_round_height === stateData.height) {
                // Viewing current round
                setRoundInfo({
                    round: stateData.height,
                    currentContributors: Object.keys(coordinatorData.current_contributors),
                    finishedContributors: Object.keys(coordinatorData.finished_contributors).filter((c) =>
                        c.startsWith('aleo')
                    ),
                    droppedContributors: coordinatorData.dropped.map((d) => d.id),
                    startedAt: stateData.startedAt,
                    percentComplete: percentComplete,
                    chunksComplete: chunksComplete,
                    finish_time: {
                        value,
                        unit: unit.join(' ')
                    }
                });
            } else {
                // Viewing previous round
                setRoundInfo({
                    round: stateData.height,
                    currentContributors: [],
                    finishedContributors: stateData.contributorIds,
                    droppedContributors: [],
                    startedAt: stateData.startedAt,
                    finishedAt: stateData.finishedAt,
                    percentComplete: percentComplete,
                    chunksComplete: chunksComplete,
                    finish_time: {
                        value,
                        unit: unit.join(' ')
                    }
                });
            }
        }
    }, [coordinatorData, stateData]);

    return (
        <Spin spinning={loading}>
            <AleoTypography textStyle="title">Setup Status Monitor</AleoTypography>

            <AleoTypography textStyle="body" style={{ maxWidth: 640 }}>
                This page shows the current status of the Aleo Setup Ceremony. The information below is a summary of the
                current round: the round height, size of the queue, the address of the contributor, and their
                contribution progress. You can also filter by ceremony type (Inner, Outer, Universal) using the selector
                below.
            </AleoTypography>

            <FilterButtonWrapper>
                <AleoTypography textStyle="body" noMargin>
                    Filter by:{' '}
                </AleoTypography>

                <FilterButtons
                    defaultValue="all"
                    size="large"
                    onChange={(e) => setFilterBy(e.target.value)}
                    value={filterBy}
                >
                    <Radio.Button value="universal">Universal Setup</Radio.Button>
                    <Radio.Button value="outer">Outer Setup</Radio.Button>
                    <Radio.Button value="inner">Inner Setup</Radio.Button>
                </FilterButtons>
            </FilterButtonWrapper>

            <Section>
                <StatRow>
                    <StatCard>
                        <AleoTypography textStyle="heading-3" weight="semibold" noMargin>
                            <span className="primary-text">{Object.values(coordinatorData?.queue ?? []).length}</span>{' '}
                            People
                        </AleoTypography>

                        <AleoTypography textStyle="body" noMargin>
                            Waiting to Contribute
                        </AleoTypography>
                    </StatCard>
                    {roundInfo && (
                        <StatCard>
                            <AleoTypography textStyle="heading-3" weight="semibold" noMargin>
                                <span className="primary-text">~{roundInfo.finish_time.value} </span>
                                {roundInfo.finish_time.unit}
                            </AleoTypography>

                            <AleoTypography textStyle="body" noMargin>
                                Until Round is Complete
                            </AleoTypography>
                        </StatCard>
                    )}
                </StatRow>

                <CoordinatorStateWrapper>
                    <Row>
                        <Col md={8} xs={24}>
                            <Form layout="vertical">
                                <Form.Item label="Round Select">
                                    <Select
                                        placeholder="Current"
                                        size="large"
                                        value={roundHeight}
                                        onSelect={setRoundHeight}
                                    >
                                        <Select.Option value={coordinatorData?.current_round_height ?? 1}>
                                            Current Round
                                        </Select.Option>

                                        {(coordinatorData?.current_round_height ?? 0) > 0 && Array.from(Array((coordinatorData?.current_round_height ?? 1) - 1).keys()).map(
                                            (n) => (
                                                <Select.Option value={n + 1} key={n}>
                                                    Round {n + 1}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    {!stateError && roundInfo && (
                        <>
                            <AleoTypography textStyle="heading-4">Round Information</AleoTypography>

                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Viewing Round</th>
                                        <td colSpan={2}>{roundInfo.round}</td>
                                    </tr>
                                    <tr>
                                        <th>Current Contributors</th>
                                        <td>{roundInfo.currentContributors.length}</td>
                                        <HideMobile>{roundInfo.currentContributors.join(', ')}</HideMobile>
                                    </tr>
                                    <tr>
                                        <th>Finished Contributors</th>
                                        <td>{roundInfo.finishedContributors.length}</td>
                                        <HideMobile>{roundInfo.finishedContributors.join(', ')}</HideMobile>
                                    </tr>
                                    <tr>
                                        <th>Dropped Contributors</th>
                                        <td>{roundInfo.droppedContributors.length}</td>
                                        <HideMobile>{roundInfo.droppedContributors.join(', ')}</HideMobile>
                                    </tr>
                                    <tr>
                                        <th>Started At</th>
                                        <td className="primary-text" colSpan={2}>
                                            {dayjs(stateData?.startedAt).format('MMMM D, YYYY h:mm A')}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Finished At</th>
                                        <td className="primary-text" colSpan={2}>
                                            {stateData?.finishedAt &&
                                                dayjs(stateData.finishedAt).format('MMMM D, YYYY h:mm A')}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                </CoordinatorStateWrapper>
            </Section>

            <Section>
                <AleoTypography textStyle="heading-4">Round Status</AleoTypography>

                {roundInfo && (
                    <Tooltip title={`${roundInfo.percentComplete}%`}>
                        <RoundProgress
                            percent={roundInfo.percentComplete}
                            format={() => `${roundInfo?.chunksComplete} / ${stateData?.chunks.length} chunks`}
                        />
                    </Tooltip>
                )}
            </Section>

            {stateError && (
                <Result
                    status="error"
                    title="Invalid Round"
                    subTitle="There are no records for this round. Please select another round."
                    icon={<DeleteCircleIcon />}
                />
            )}
        </Spin>
    );
};

export default Status;
