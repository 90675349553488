import React, { useState } from 'react';
import { BackUpButtons, BackUpContent, BackUpProps, BackUpWrapper } from '../common';
import { AleoTypography } from '@aleohq/components';
import { Button, Checkbox, Col, Row } from 'antd';
import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';

const PhraseWrapper = styled.div`
    margin-top: 65px;
    margin-bottom: 20px;
    max-width: 540px;
    border-top: 1px solid ${palette.gray4};
    border-bottom: 1px solid ${palette.gray4};
    padding: 20px 0 20px 40px;
`;

const Number = styled.span`
    color: ${palette.gray6};
    margin-right: 10px;
`;

const BackUpMnemonic = (props: BackUpProps) => {
    const [hasSaved, setHasSaved] = useState(false);
    const words = [
        'issue',
        'dinner',
        'human',
        'horse',
        'mechanic',
        'exercise',
        'park',
        'sword',
        'thumb',
        'brown',
        'box',
        'era',
        'ring',
        'maze',
        'young'
    ];

    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3">Save your backup phrase.</AleoTypography>

                <AleoTypography textStyle="body" style={{ maxWidth: 500 }}>
                    This is your backup phrase. You use it to prove that this is your wallet. You only get to see it
                    once! Keep it in a secure place.
                </AleoTypography>

                <PhraseWrapper>
                    <Row gutter={[0, 20]}>
                        {words.map((word, i) => {
                            return (
                                <Col span={8} key={i}>
                                    <AleoTypography textStyle="body">
                                        <Number>#{(i + 1).toString(10).padStart(2, '0')}</Number> {word}
                                    </AleoTypography>
                                </Col>
                            );
                        })}
                    </Row>
                </PhraseWrapper>

                <Button size="large" type="primary">
                    Copy Phrases
                </Button>

                <Checkbox style={{ marginTop: 20 }} onChange={(e) => setHasSaved(e.target.checked)}>
                    I have saved the above phrase
                </Checkbox>
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={() => props.onBack()}>
                    Back
                </Button>

                <Button type="primary" size="large" disabled={!hasSaved} onClick={() => props.onNext('SUCCESS')}>
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default BackUpMnemonic;
