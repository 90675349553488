import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import '@aleohq/ui/dist/green.css';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParse from 'dayjs/plugin/customParseFormat';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';
import { AleoThemeProvider } from '@aleohq/components';
import ErrorBoundary from './components/ErrorBoundary';
import { SWRConfig } from 'swr';
import { client } from './api/client';

dayjs.extend(relativeTime);
dayjs.extend(customParse);

ReactDOM.render(
    <Provider store={store}>
        <AleoThemeProvider theme="green">
            <ErrorBoundary>
                <SWRConfig value={{ fetcher: client, shouldRetryOnError: false }}>
                    <App />
                </SWRConfig>
            </ErrorBoundary>
        </AleoThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
