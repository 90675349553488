import React, { useState } from 'react';
import { BackUpButtons, BackUpContent, BackUpPage, BackUpProps, BackUpWrapper } from '../common';
import { palette } from '@aleohq/ui/dist/palette';
import { Button, Modal, Radio } from 'antd';
import styled from 'styled-components';
import menuCheckSvg from '../../../assets/icons/menu-check.svg';
import { AleoTypography } from '@aleohq/components';

const BackUpRadio = styled(Radio.Group)`
    display: flex;
    margin-top: 65px;
    width: 100%;
    gap: 40px;

    .ant-radio-button-wrapper {
        height: 52px;
        flex: 1;
        background: ${palette.gray1};
        border: 1px solid ${palette.gray9};
        border-radius: 25px;
        display: flex;
        align-items: center;
        font-size: 16px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &.ant-radio-button-wrapper-checked {
            border: 1px solid ${palette.green6};
            font-weight: 600;
            &:after {
                opacity: 1;
            }
        }
        &:after {
            content: url(${menuCheckSvg});
            height: 24px;
            width: 24px;
            opacity: 0;
            transition: 0.3s opacity;
        }
        span {
            flex: 1;
        }
        &:before {
            display: none;
        }
        &:hover {
            color: ${palette.green7};
            &:not(.ant-radio-button-wrapper-checked):after {
                opacity: 0.5;
            }
        }
    }
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
        border-color: inherit;
    }
`;

const List = styled.ul`
    padding-inline-start: 15px;
    margin-top: 25px;

    li {
        margin-bottom: 20px;
    }

    li::marker {
        color: ${palette.green6};
    }
`;

const BackUpMenu = (props: BackUpProps) => {
    const [selectedType, setSelectedType] = useState<BackUpPage>();

    const options: { label: string; value: BackUpPage }[] = [
        {
            label: 'Mnemonic Phrase',
            value: 'MNEMONIC'
        },
        {
            label: 'Private Keystring',
            value: 'PRIVATE_KEY'
        },
        {
            label: 'JSON keystore file download',
            value: 'JSON_FILE'
        }
    ];

    const confirmContinue = () => {
        Modal.info({
            title: <AleoTypography textStyle="subtitle">Attention!</AleoTypography>,
            content: (
                <List>
                    <li>
                        <AleoTypography textStyle="body">
                            Do not lose it. Your private key cannot be recovered if you lose it.
                        </AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">Do not screenshot it.</AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">
                            Write it down on paper and hide it somewhere, put it in a safety deposit box, or use a
                            secure password manager.
                        </AleoTypography>
                    </li>
                    <li>
                        <AleoTypography textStyle="body">
                            The Aleo Team cannot recover your account if you lose your private key.
                        </AleoTypography>
                    </li>
                </List>
            ),
            centered: true,
            icon: null,
            width: '415px',
            okButtonProps: {
                size: 'large'
            },
            okText: 'I Understand',
            className: 'setup-modal',
            onOk: () => props.onNext(selectedType)
        });
    };

    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3" style={{ width: 365 }}>
                    How would you like to backup your Aleo wallet?
                </AleoTypography>

                <AleoTypography textStyle="body">Please select from one of the three options below.</AleoTypography>

                <BackUpRadio
                    options={options}
                    optionType="button"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                />
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={() => props.onBack()}>
                    Back
                </Button>

                <Button
                    type="primary"
                    size="large"
                    disabled={!selectedType}
                    onClick={() => selectedType && confirmContinue()}
                >
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default BackUpMenu;
