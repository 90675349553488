import React from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalProps } from 'antd';
import { AleoTypography } from '@aleohq/components';
import { palette } from '@aleohq/ui/dist/palette';
import chromeIcon from '../../assets/chrome.png';
import edgeIcon from '../../assets/edge.png';
import firefoxIcon from '../../assets/firefox.png';

const StyledModal = styled(Modal)`
    .ant-modal-content .ant-modal-body {
        text-align: center;
        padding: 40px;
    }
`;

const BrowserListRow = styled.div`
    display: flex;
    gap: 65px;
    margin: 85px auto;
`;

const BrowserIcon = styled.div<{ src: string }>`
    height: 42px;
    width: 42px;
    background-image: url(${(props) => props.src});
    background-size: contain;
    margin: auto;
`;

const BrowserListItem = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 32px;
`;

const UnsupportedBrowserModal = (props: ModalProps) => {
    return (
        <StyledModal width={750} closable={false} footer={false} {...props}>
            <AleoTypography textStyle="heading-3" weight="semibold" style={{ color: palette.red6 }}>
                You seem to be using
                <br />
                an unsupported browser
            </AleoTypography>

            <AleoTypography textStyle="body">
                To be able to participate in the brower setup, please
                <br />
                access the site with a supported browser from the list below
            </AleoTypography>

            <BrowserListRow>
                <BrowserListItem>
                    <BrowserIcon src={chromeIcon} />
                    <AleoTypography textStyle="body" style={{ margin: '20px 0 0 0' }}>
                        Chrome
                    </AleoTypography>
                    <AleoTypography textStyle="body" type="secondary">
                        83
                    </AleoTypography>
                </BrowserListItem>
                <BrowserListItem>
                    <BrowserIcon src={edgeIcon} />
                    <AleoTypography textStyle="body" style={{ margin: '20px 0 0 0' }}>
                        Edge
                    </AleoTypography>
                    <AleoTypography textStyle="body" type="secondary">
                        83
                    </AleoTypography>
                </BrowserListItem>
                <BrowserListItem>
                    <BrowserIcon src={firefoxIcon} />
                    <AleoTypography textStyle="body" style={{ margin: '20px 0 0 0' }}>
                        Firefox
                    </AleoTypography>
                    <AleoTypography textStyle="body" type="secondary">
                        79
                    </AleoTypography>
                </BrowserListItem>
            </BrowserListRow>

            <div>
                <Button type="primary" size="large" onClick={props.onCancel}>
                    Got It
                </Button>
            </div>
        </StyledModal>
    );
};

export default UnsupportedBrowserModal;
